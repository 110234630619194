<template>
    <b-card>
        <b-row class="mb-2">
            <b-col class="text-right">
                <b-button variant="primary" :to="{ name: 'ExpenseNew' }">
                    <font-awesome-icon icon="plus"></font-awesome-icon> Nuevo
                </b-button>
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col sm="5" md="6" class="my-1">
                <b-input-group size="sm" prepend="Mostrar" append="registros">
                    <b-form-select v-model="expenses.itemsPerPage" id="perPageSelect" size="sm" :options="expenses.pageOptions"
                                   style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>

            <!--<b-col sm="7" md="6" class="my-1">
                <b-input-group prepend="Filtrar: ">
                    <b-input v-model="branches.filter" debounce="300"></b-input>
                </b-input-group>
            </b-col>-->
        </b-row>
        <b-table :items="loadData" :fields="expenses.fields" :filter="expenses.filter" :current-page="expenses.currentPage"
                 :per-page="expenses.itemsPerPage" empty-text="Sin registros" empty-filtered-text="Sin coincidencias"
                 striped bordered hover small show-empty :responsive="true" :sort-by="expenses.sortBy" :sort-desc="expenses.sortDesc"
                 ref="expensesTable" class="text-left mb-0" @context-changed="tableContextChanged">
            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in expenses.fields" :key="field.key"
                          :class="expenses.filters[index] && expenses.filters[index].value ? 'non-empty' : ''">
                        <b-row v-if="expenses.filters[index]">
                            <b-col v-if="expenses.filters[index].inputType === 'Select'">
                                <b-select v-model="expenses.filters[index].value">
                                    <template v-if="'selectOptions' in expenses.filters[index]">
                                        <b-select-option v-for="option in expenses.filters[index].selectOptions" :value="option.value" :key="option.value">
                                            {{ option.text }}
                                        </b-select-option>
                                    </template>
                                    <template v-else>
                                        <b-select-option value="">Todos</b-select-option>
                                        <b-select-option value="TRUE">Cancelado</b-select-option>
                                        <b-select-option value="FALSE">Activo</b-select-option>
                                    </template>
                                </b-select>
                            </b-col>
                            <b-col v-else-if="expenses.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="expenses.filters[index].value" debounce="300"></b-input>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>

            <template #cell(id)="row">
                <router-link :to="{ name: 'ExpenseShow', params: { id: row.item.id }}" target="_blank">{{ row.item.id }}</router-link>
            </template>

            <template #cell(objectType)="row">
                <p v-if="row.item.objectType === 'ServiceOrder'">Orden de Servicio</p>
                <p v-if="row.item.objectType === 'General'">General</p>
            </template>

            <template #cell(objectFolio)="row">
                <p v-if="row.item.objectFolio">
                    <router-link v-if="row.item.objectType === 'ServiceOrder'" :to="{ name: 'ServiceOrderShow', params: { id: row.item.objectId }}" target="_blank">
                        {{ row.item.objectFolio }}
                    </router-link>
                </p>
                <p v-else>Sin registro</p>
            </template>

            <template #cell(paymentType)="row">
                <p v-if="row.item.paymentType === 'CASH'">Contado</p>
                <p v-if="row.item.paymentType === 'CREDIT'">Crédito</p>
            </template>

            <template #cell(unitPrice)="row">
                <p>$ {{ formatCurrency(row.item.unitPrice) }}</p>
            </template>

            <template #cell(total)="row">
                <p>$ {{ formatCurrency(row.item.total) }}</p>
            </template>

            <template #cell(isExtra)="row">
                <b-badge variant="warning" v-if="!row.item.isExtra" class="p-1">No</b-badge>
                <b-badge variant="primary" v-if="row.item.isExtra" class="p-1">Si</b-badge>
            </template>

            <template #cell(isCancelled)="row">
                <b-badge variant="danger" v-if="row.item.isCancelled" class="p-1">Cancelado</b-badge>
                <b-badge variant="primary" v-else>Activo</b-badge>
            </template>

            <template #cell(actions)="row">
                <b-button-group size="sm">
                    <b-button size="sm" :to="{ name: 'ExpenseShow', params: { id: row.item.id }}" v-b-tooltip.hover
                              title="Detalle" variant="outline-primary">
                        <font-awesome-icon icon="search"></font-awesome-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        Mostrando {{ start }} a {{ end }} de {{ expenses.totalRows }} registro<template v-if="expenses.totalRows !== 1">s</template>
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="expenses.currentPage" :total-rows="expenses.totalRows"
                                      :per-page="expenses.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>
    </b-card>
</template>

<script>
import { expenseListUrl } from '@routes';
import * as constants from '@constants';
import tableStateMixin from '../../mixins/tableState';
import { formatCurrency } from '../../js/utilities';

export default {
    mixins: [tableStateMixin],
    data() {
        return {
            expenses: {
                fields: [
                    // { key: 'id', label: 'id', sortable: true },
                    { key: 'createdDate', label: 'Fecha', sortable: true },
                    { key: 'id', label: 'Folio', sortable: true },
                    { key: 'objectType', label: 'Tipo', sortable: true },
                    { key: 'objectFolio', label: 'Documento relacionado', sortable: true },
                    { key: 'name', label: 'Nombre', sortable: true },
                    { key: 'paymentType', label: 'Tipo de pago', sortable: true },
                    { key: 'qty', label: 'Cantidad', sortable: true, thStyle: 'width: 8%', class: 'text-right' },
                    { key: 'unitPrice', label: 'Precio Unitario', sortable: true, thStyle: 'width: 8%', class: 'text-right' },
                    { key: 'total', label: 'Total', sortable: true, thStyle: 'width: 8%', class: 'text-right' },
                    { key: 'isExtra', label: 'Extra', sortable: true, class: 'text-center' },
                    { key: 'isCancelled', label: 'Estatus', sortable: true, class: 'text-center' },
                    { key: 'actions', label: 'Acciones', class: 'text-center' }
                ],
                filters: [
                    { column: 'DATE_FORMAT(e.created, \'%d/%m/%Y %H:%i\')', type: 'STRING', value: '', format: '' },
                    { column: 'e.id', type: 'STRING', value: '', format: '' },
                    { column: 'e.object_type', type: 'STRING', value: '', format: '', inputType: 'Select',
                        selectOptions: [{ value: '', text: 'Todos' }, { value: 'ServiceOrder', text: 'Orden de servicio' }, { value: 'General', text: 'General' }]
                    },
                    { column: 'e.object_folio', type: 'STRING', value: '', format: '' },
                    { column: 'e.name', type: 'STRING', value: '', format: '' },
                    { column: 'e.payment_type', type: 'STRING', value: '', format: '', inputType: 'Select',
                        selectOptions: [{ value: '', text: 'Todos' }, { value: 'CASH', text: 'Contado' }, { value: 'CREDIT', text: 'Crédito' }]
                    },
                    { value: '' },
                    { value: '' },
                    { value: '' },
                    { column: 'e.is_extra', type: 'BOOLEAN', value: '', format: '', inputType: 'Select',
                        selectOptions: [{ value: '', text: 'Todos' }, { value: 'TRUE', text: 'Si' }, { value: 'FALSE', text: 'No' }]
                    },
                    { column: 'e.is_cancelled', type: 'BOOLEAN', value: '', format: '', inputType: 'Select' }
                ],
                filter: '',
                items: [],
                isLoading: false,
                pageOptions: [25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'e.created',
                sortDesc: false
            },
            breadcrumb: {
                title: 'Gastos',
                path: []
            }
        };
    },
    created() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.restoreTableState(this.expenses, this.tableState);
    },
    methods: {
        formatCurrency: formatCurrency,
        loadData(ctx) {
            if (ctx.sortBy !== '') {
                let data = {
                    filters: this.expenses.filters.filter(filter => filter.value.trim() !== ''),
                    ...ctx
                };

                data.currentPage = (data.currentPage - 1) * data.perPage;

                return this.axios.post(expenseListUrl(), data).then(response => {
                    if (response.data.code === constants.CODE_OK) {
                        this.expenses.totalRows = response.data.totalRows;
                        this.expenses.items = [];

                        for (let value of response.data.expenses) {
                            this.expenses.items.push(value);
                        }

                        return response.data.expenses;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    console.error(error);

                    return [];
                });
            }
        },
        tableContextChanged(context) {
            this.expenses.sortBy = context.sortBy;
            this.expenses.sortDesc = context.sortDesc;

            this.saveTableState(this.$route.name, this.expenses);
        }
    },
    watch: {
        'expenses.filters': {
            handler() {
                this.$refs.expensesTable.refresh();
                this.saveTableState(this.$route.name, this.expenses);
            },
            deep: true
        }
    },
    computed: {
        start: function() {
            return this.expenses.totalRows === 0 ? 0 : (this.expenses.currentPage - 1) * this.expenses.itemsPerPage + 1;
        },
        end: function() {
            let offset = (this.expenses.currentPage - 1) * this.expenses.itemsPerPage;

            return (this.expenses.totalRows - offset) < this.expenses.itemsPerPage ? this.expenses.totalRows : offset + this.expenses.itemsPerPage;
        }
    }
};
</script>

<style scoped>

</style>